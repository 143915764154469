{
  "name": "chart",
  "version": "2025.2.13-3b527deb",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --proxy-config src/proxy.conf.json",
    "proxy": "http-proxy -v -p 4200 /token=https://siemens-qa-00043.eu.auth0.com/oauth/token 8080",
    "dev": "ng serve -c dev-backendless",
    "ui:dev": "ng run ui:serve",
    "start:dev": "ng serve -c dev",
    "build": "node copy-images.js && ng build",
    "lint": "ng lint",
    "vitest": "vitest",
    "test": "jest --config src/jest.config.ts",
    "test:watch": "jest --config src/jest.config.ts --watch",
    "test:coverage": "jest --coverage",
    "jest": "jest --config src/jest.config.ts",
    "closure": "java -jar node_modules/google-closure-compiler/compiler.jar --flagfile closure.conf",
    "closure-debug": "java -jar node_modules/google-closure-compiler/compiler.jar --flagfile closure.conf --debug --formatting PRETTY_PRINT"
  },
  "private": true,
  "dependencies": {
    "@angular-slider/ngx-slider": "^18.0.0",
    "@angular/animations": "^18.1.3",
    "@angular/cdk": "^18.1.3",
    "@angular/common": "^18.1.3",
    "@angular/compiler": "^18.1.3",
    "@angular/core": "^18.1.3",
    "@angular/forms": "^18.1.3",
    "@angular/material": "^18.1.3",
    "@angular/platform-browser": "^18.1.3",
    "@angular/platform-browser-dynamic": "^18.1.3",
    "@angular/router": "^18.1.3",
    "@auth0/angular-jwt": "~5.1.2",
    "@auth0/auth0-angular": "^2.2.3",
    "@christian.krey/tailwind-presets": "^0.1.0",
    "@ng-idle/core": "^15.0.0",
    "@ng-idle/keepalive": "^15.0.0",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/store": "^18.0.2",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@tanstack/angular-table": "^8.20.5",
    "angular-highcharts": "^17.0.1",
    "axios": "^1.6.8",
    "highcharts": "^12.1.2",
    "highcharts-angular": "^4.0.1",
    "jest-junit": "^16.0.0",
    "ng-event-bus": "^7.0.1",
    "ngx-color-picker": "^16.0.0",
    "ngx-cookie-service": "^18.0.0",
    "ngx-toastr": "^18.0.0",
    "rxjs": "~7.8.1",
    "tailwind-merge": "^3.0.1",
    "ts-jest": "^29.1.2",
    "zone.js": "~0.14.5"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.3",
    "@angular-eslint/builder": "17.3.0",
    "@angular-eslint/eslint-plugin": "17.3.0",
    "@angular-eslint/eslint-plugin-template": "17.3.0",
    "@angular-eslint/schematics": "^18.1.0",
    "@angular-eslint/template-parser": "17.3.0",
    "@angular/cli": "^18.1.3",
    "@angular/compiler-cli": "^18.1.3",
    "@testing-library/angular": "^17.3.4",
    "@testing-library/dom": "^10.4.0",
    "@testing-library/jest-dom": "^6.6.3",
    "@testing-library/user-event": "^14.5.2",
    "@types/jest": "^28.1.1",
    "@types/node": "^18.11.18",
    "@typescript-eslint/eslint-plugin": "^7.9.0",
    "@typescript-eslint/parser": "^7.9.0",
    "autoprefixer": "^10.4.20",
    "copy-webpack-plugin": "^11.0.0",
    "eslint": "^8.56.0",
    "jest": "^29.7.0",
    "jest-fixed-jsdom": "^0.0.9",
    "jest-preset-angular": "^14.5.0",
    "msw": "^2.7.0",
    "postcss": "^8.4.47",
    "prettier": "3.3.3",
    "prettier-plugin-tailwindcss": "^0.6.11",
    "tailwindcss": "^3.4.13",
    "typescript": "^5.4.5",
    "vitest": "^3.0.4",
    "webpack-cli": "^5.1.4"
  },
  "optionalDependencies": {
    "@nx/nx-linux-x64-gnu": "18.0.4"
  },
  "workspaces": [
    "packages/facade"
  ],
  "msw": {
    "workerDirectory": [
      "src"
    ]
  }
}
